<template>
    <div class="inner-section application-form-wrapper">
      <bread-cumb />
      <body-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('teaGarden.return_form_3') }}</h4>
        </template>
        <template v-slot:body>
          <b-overlay :show="loader">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <!-- <b-overlay :show="loading"> -->
              <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                <b-row>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Garden Name" vid="year" rules="required|min_value:1" v-slot="{ errors }">
                      <b-form-group
                        label-for="year">
                        <template v-slot:label>
                          {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formData.year"
                          :options="yearList"
                          id="year"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Garden Name" vid="return_type" rules="required|min_value:1" v-slot="{ errors }">
                      <b-form-group
                        label-for="return_type">
                        <template v-slot:label>
                          {{ $t('globalTrans.type') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-radio-group
                            v-model="formData.return_type"
                            :options="returnTypeList"
                            @change="resetGarden()"
                            :disabled="(isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId()) ? true : false"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-radio-group>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row  v-if="formData.return_type === 1">
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Garden Factory Id" vid="garden_fact_id" rules="required|min_value:1" v-slot="{ errors }">
                      <b-form-group
                        label-for="garden_fact_id">
                        <template v-slot:label>
                          {{ $t('teaGarden.garden_factory_id') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formData.garden_fact_id"
                          :options="masterTeaGardenGenInfoList"
                          :disabled="(isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId()) ? true : false"
                          @change="getGfAutoId(formData.garden_fact_id, 'garden')"
                          id="garden_factory_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col v-if="formData.garden_fact_id" sm="6">
                      <ValidationProvider name="Garden ID" vid="garden_id" v-slot="{ errors }">
                          <b-form-group label-for="garden_id">
                              <template v-slot:label>
                                  {{ $t('teaGarden.tea_garden_id') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input v-model="gf_auto_id" disabled :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row  v-if="formData.return_type === 2">
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Garden Factory Id" vid="garden_factory_id" rules="required|min_value:1" v-slot="{ errors }">
                      <b-form-group
                        label-for="garden_factory_id">
                        <template v-slot:label>
                          {{ $t('teaGarden.garden_factory_id') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          :disabled="(isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId()) ? true : false"
                          plain
                          v-model="formData.garden_fact_id"
                          :options="masterBoughtLeafFactoryList"
                          @change="getGfAutoId(formData.garden_fact_id, 'garden')"
                          id="garden_factory_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col v-if="formData.garden_fact_id" sm="6">
                      <ValidationProvider name="Factory ID" vid="factory_id" v-slot="{ errors }">
                          <b-form-group label-for="factory_id">
                              <template v-slot:label>
                                  {{ $t('teaGarden.bought_auto_id') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input v-model="gf_auto_id" disabled :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="6">
                        <ValidationProvider name="Date" vid="date" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label-for="date"
                            >
                                <template v-slot:label>
                                {{ $t('globalTrans.date') }}  <span class="text-danger">*</span>
                                </template>
                                <date-picker
                                id="date"
                                class="form-control"
                                v-model="formData.date"
                                :class="errors[0] ? 'is-invalid' : ''"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('globalTrans.select')"
                                :config="{ static: true }"
                                >
                                </date-picker>
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
              </b-form>
            <!-- </b-overlay> -->
          </ValidationObserver>
          <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset" autocomplete="off">
              <b-row class="p-4">
                <b-col style="background-color: #B6D0E2;" lg="6" class="offset-lg-3 p-2">
                  <template>
                    <h6 style="" class="text-sm-center">{{ $t('teaGarden.staff_info') }}</h6>
                  </template>
                </b-col>
              </b-row>
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Employee Type" vid="emp_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="emp_type"
                    >
                      <template v-slot:label>
                        {{$t('teaGarden.staff_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="emp_type"
                        :options="staffTypeList"
                        v-model="detailsFormData.emp_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="detailsFormData.emp_type === 1">
                  <ValidationProvider name="Employee Type" vid="emp_lab_id" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="emp_lab_id"
                    >
                      <template v-slot:label>
                        {{$t('teaGarden.employees')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="emp_lab_id"
                        :options="employeeList"
                        v-model="detailsFormData.emp_lab_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="detailsFormData.emp_type === 2">
                  <ValidationProvider name="Employee Type" vid="emp_lab_id" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="emp_lab_id"
                    >
                      <template v-slot:label>
                        {{$t('teaGarden.employees')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="emp_lab_id"
                        :options="labourerList"
                        v-model="detailsFormData.emp_lab_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Bangladeshi" vid="ban_emp" :rules="{regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="ban_emp"
                    >
                      <template v-slot:label>
                        {{ $t('teaGarden.ban_emp') }}
                      </template>
                      <b-form-input
                        id="ban_emp"
                        v-model="detailsFormData.ban_emp"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        @input="updateTotalPopulation()"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Foreigner" vid="foreign_emp" :rules="{regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="foreign_emp"
                    >
                      <template v-slot:label>
                        {{ $t('teaGarden.foreign_emp') }}
                      </template>
                      <b-form-input
                        id="foreign_emp"
                        v-model="detailsFormData.foreign_emp"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        @input="updateTotalPopulation()"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Surplus Person" vid="surplus_person" :rules="{regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="surplus_person"
                    >
                      <template v-slot:label>
                        {{ $t('teaGarden.surplus_person') }}
                      </template>
                      <b-form-input
                        id="surplus_person"
                        v-model="detailsFormData.surplus_person"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Dificit" vid="dificit" :rules="{regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="dificit"
                    >
                      <template v-slot:label>
                        {{ $t('teaGarden.dificit') }}
                      </template>
                      <b-form-input
                        id="dificit"
                        v-model="detailsFormData.dificit"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="dependents" vid="dependents" :rules="{regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="banglideshi"
                    >
                      <template v-slot:label>
                        {{ $t('teaGarden.dependents') }}
                      </template>
                      <b-form-input
                        id="dependents"
                        v-model="detailsFormData.dependents"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        @input="updateTotalPopulation()"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="total_population" vid="total_population" :rules="{regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="banglideshi"
                    >
                      <template v-slot:label>
                        {{ $t('teaGarden.total_population') }}
                      </template>
                      <b-form-input
                        id="total_population"
                        v-model="detailsFormData.total_population"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Avg Salary" vid="avg_salary" :rules="{regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                    <b-form-group
                      label-cols-sm="5"
                      label-for="avg_salary"
                    >
                      <template v-slot:label>
                        {{ $t('teaGarden.avg_salary') }}
                      </template>
                      <b-form-input
                        id="avg_salary"
                        v-model="detailsFormData.avg_salary"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col>
                    <div class="text-right mb-2">
                        <b-button type="button" v-if="editIndex !== ''" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                        <b-button type="submit" class="btn-sm" variant="success">{{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                    </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
              <br/>
            <!-- List  -->
            <b-table-simple bordered>
              <thead>
                <tr class="bg-primary">
                  <th style="width:5%">{{ $t('globalTrans.sl_no') }}</th>
                  <th style="width:8%">{{ $t('teaGarden.staff_type') }}</th>
                  <th style="width:10%">{{ $t('teaGarden.employees') }}</th>
                  <th style="width:7%">{{ $t('teaGarden.ban_emp') }}</th>
                  <th style="width:10%">{{ $t('teaGarden.foreign_emp') }}</th>
                  <th style="width:10%">{{ $t('teaGarden.surplus_person') }}</th>
                  <th style="width:7%">{{ $t('teaGarden.dificit') }}</th>
                  <th style="width:10%">{{ $t('teaGarden.dependents') }}</th>
                  <th style="width:10%">{{ $t('teaGarden.total_population') }}</th>
                  <th style="width:10%">{{ $t('teaGarden.avg_salary') }}</th>
                  <th style="width:10%">{{ $t('globalTrans.action') }}</th>
                </tr>
              </thead>
              <b-tbody>
                <b-tr v-for="(item, index) in formData.details" :key="index">
                  <b-td>
                      {{ $n(index + 1) }}
                  </b-td>
                  <b-td>
                    {{ getStaffTypeList(item.emp_type) }}
                  </b-td>
                  <b-td>
                    <span v-if="item.emp_type === 1">{{ getEmployeeList(item.emp_lab_id) }}</span>
                    <span v-if="item.emp_type === 2">{{ getLabourerList(item.emp_lab_id) }}</span>
                  </b-td>
                  <b-td>
                    {{ $n(item.ban_emp) }}
                  </b-td>
                  <b-td>
                    {{ $n(item.foreign_emp) }}
                  </b-td>
                  <b-td>
                    {{ $n(item.surplus_person) }}
                  </b-td>
                  <b-td>
                    {{ $n(item.dificit) }}
                  </b-td>
                  <b-td>
                    {{ $n(item.dependents) }}
                  </b-td>
                  <b-td>
                    {{ $n(item.total_population) }}
                  </b-td>
                  <b-td class="text-right">
                    {{ $n(item.avg_salary, { minimumFractionDigits: 2 }) }}
                  </b-td>
                  <b-td>
                    <span class="d-flex text-center">
                      <b-button @click="editItem(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </span>
                  </b-td>
              </b-tr>
              <b-tr v-if="!formData.details || formData.details.length <= 0">
                      <td colspan="11" class="text-center">
                          <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                      </td>
                  </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-row class="text-right">
              <b-col>
                <!-- <b-button type="button" @click="saveUpdate" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button> -->
                <b-button type="button" @click="saveUpdate()" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
                <b-button variant="danger" type="button" class="btn-sm" @click="back">{{ $t('teaGardenConfig.back') }}</b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </template>
      </body-card>
    </div>
</template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { returnThreeStoreApi, returnThreeUpdateApi, returnThreeShowApi, preYearData } from '../../api/routes'
  // import { helpers } from '@/utils/helper-functions'
  import BreadCumb from '@/components/BreadCumb.vue'
  import teaGardenService from '@/mixins/tea-garden-service'
  import { helpers } from '@/utils/helper-functions'
  export default {
    mixins: [teaGardenService],
    name: 'Form',
    props: ['id'],
    components: { BreadCumb },
    data () {
      return {
        valid: null,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        errors: [],
        status: 1,
        editIndex: '',
        gf_auto_id: '',
        formData: {
          year: 0,
          return_type: 0,
          garden_fact_id: 0,
          date: '',
          details: []
        },
        detailsFormData: {
            emp_type: 0,
            emp_lab_id: 0,
            ban_emp: 0,
            foreign_emp: 0,
            surplus_person: 0,
            dificit: 0,
            dependents: 0,
            total_population: 0,
            avg_salary: 0
        },
        gardenFactoryList: [],
        checkAll: false,
        loader: false,
        isGardenAdmin: false,
        taskUnitId: 0
      }
    },
    computed: {
      returnTypeList () {
        return [
            { text: this.$i18n.locale === 'bn' ? 'বাগান' : 'Garden', value: 1 },
            { text: this.$i18n.locale === 'bn' ? 'কারখানা' : 'Factory', value: 2 }
        ]
      },
      loading: function () {
        return this.$store.state.commonObj.loading
      },
      yearList () {
        return helpers.getYearList()
      },
      employeeList () {
        return this.$store.state.TeaGardenService.commonObj.employeeList
      },
      labourerList () {
        return this.$store.state.TeaGardenService.commonObj.labourerList
      },
      staffTypeList () {
        return this.$store.state.TeaGardenService.commonObj.staffTypeList
      },
      masterTeaGardenGenInfoList () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList
      },
      masterBoughtLeafFactoryList () {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1 && el.factory_type_id === 2)
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    watch: {
    },
    created () {
        if (this.$route.query.id) {
            this.getFormData(this.$route.query.id)
            const typeName = this.formData.return_type === 1 ? 'garden' : 'factory'
            this.getGfAutoId(this.formData.garden_fact_id, typeName)
        } else {
            this.formData.year = new Date().getFullYear()
            if (this.isGardenAdminCheckGardenId()) {
                this.formData.return_type = 1
                this.formData.garden_fact_id = this.isGardenAdminCheckGardenId()
                const typeName = 'garden'
                this.getGfAutoId(this.formData.garden_fact_id, typeName)
            }
            if (this.isFactoryAdminCheckFactoryId()) {
                const gardenData = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === this.isFactoryAdminCheckFactoryId())
                this.formData.return_type = gardenData.factory_type_id
                this.formData.garden_fact_id = this.isFactoryAdminCheckFactoryId()
                const typeName = 'factory'
                this.getGfAutoId(this.formData.garden_fact_id, typeName)
            }
        }
    },
    methods: {
      resetGarden () {
            this.formData.garden_fact_id = 0
            this.gf_auto_id = ''
        },
      getGfAutoId (Id, typeName) {
            this.gf_auto_id = ''
            const List = typeName === 'garden' ? this.masterTeaGardenGenInfoList : this.masterBoughtLeafFactoryList
            const Obj = List.find(el => el.value === parseInt(Id))
            if (typeof Obj !== 'undefined') {
                this.gf_auto_id = typeName === 'garden' ? Obj.garden_id_no : Obj.registration_no
            }
            // Emitted to get Previous year data (works only entry mode)
            if (!this.$route.query.id) {
              this.getPreYearData()
            }
        },
      updateTotalPopulation () {
        const banEmp = parseInt(this.detailsFormData.ban_emp) ? parseInt(this.detailsFormData.ban_emp) : 0
        const foreignEmp = parseInt(this.detailsFormData.foreign_emp) ? parseInt(this.detailsFormData.foreign_emp) : 0
        const dependentsEmp = parseInt(this.detailsFormData.dependents) ? parseInt(this.detailsFormData.dependents) : 0
        this.detailsFormData.total_population = banEmp + foreignEmp + dependentsEmp
      },
      getStaffTypeList (id) {
        const objectData = this.$store.state.TeaGardenService.commonObj.staffTypeList.find(item => item.value === id)
        if (typeof objectData !== 'undefined') {
            return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
          } else {
            return ''
          }
      },
      getEmployeeList (id) {
        const objectData = this.$store.state.TeaGardenService.commonObj.employeeList.find(item => item.value === id)
        if (typeof objectData !== 'undefined') {
            return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
          } else {
            return ''
          }
      },
      getLabourerList (id) {
        const objectData = this.$store.state.TeaGardenService.commonObj.labourerList.find(item => item.value === id)
        if (typeof objectData !== 'undefined') {
            return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
          } else {
            return ''
          }
      },
      getGardenName (id) {
        const objectData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
        if (typeof objectData !== 'undefined') {
            return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
          } else {
            return ''
          }
      },
      EngBangNum (n) {
        if (this.$i18n.locale === 'bn') {
          return n.toString().replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
        } else {
          return n.toString().replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
        }
      },
      cancelItem () {
          this.detailsFormData = {}
          this.editIndex = ''
          this.$refs.form1.reset()
      },
      editItem (item, index) {
          this.detailsFormData = Object.assign({}, item)
          this.editIndex = index
      },
      deleteItem (index) {
        this.$swal({
          title: this.$t('globalTrans.deleteConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.formData.details.splice(index, 1)
            this.editIndex = ''
          }
        })
      },
      async addItem () {
        let result = []
        if (this.editIndex !== '') {
            const editList = [...this.formData.details]
            editList.splice(parseInt(this.editIndex), 1)
            result = [...editList]
        } else {
            result = this.formData.details
        }
        const empType = this.detailsFormData.emp_type
        const empLabId = this.detailsFormData.emp_lab_id
        const newData = result.find(item => item.emp_type === parseInt(empType) && item.emp_lab_id === parseInt(empLabId))
        if (typeof newData === 'undefined') {
            if (this.editIndex !== '') {
                this.formData.details[parseInt(this.editIndex)].emp_type = this.detailsFormData.emp_type
                this.formData.details[parseInt(this.editIndex)].emp_lab_id = this.detailsFormData.emp_lab_id
                this.formData.details[parseInt(this.editIndex)].ban_emp = this.detailsFormData.ban_emp
                this.formData.details[parseInt(this.editIndex)].foreign_emp = this.detailsFormData.foreign_emp
                this.formData.details[parseInt(this.editIndex)].surplus_person = this.detailsFormData.surplus_person
                this.formData.details[parseInt(this.editIndex)].dificit = this.detailsFormData.dificit
                this.formData.details[parseInt(this.editIndex)].dependents = this.detailsFormData.dependents
                this.formData.details[parseInt(this.editIndex)].total_population = this.detailsFormData.total_population
                this.formData.details[parseInt(this.editIndex)].avg_salary = this.detailsFormData.avg_salary
            } else {
              this.formData.details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
            }
            this.detailsFormData = {
              emp_type: this.detailsFormData.emp_type,
              emp_lab_id: 0,
              ban_emp: 0,
              foreign_emp: 0,
              surplus_person: 0,
              dificit: 0,
              dependents: 0,
              total_population: 0,
              avg_salary: 0
            }
            this.editIndex = ''
        } else {
            this.$toast.error({
                title: '!',
                message: 'Item already added',
                color: '#ee5253'
            })
        }
        // reset form
        this.$nextTick(() => {
            this.$refs.form1.reset()
        })
      },
      finalSave () {
        this.$swal({
          title: this.$t('globalTrans.final_save_msg'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.status = 2
            this.saveUpdate()
          }
        })
      },
      async saveUpdate () {
        var check = await this.$refs.form.validate()
        if (check) {
          if (!this.formData.details.length) {
            return this.$toast.error({
              title: this.$t('globalTrans.error'),
              message: 'Add at least one item!',
              color: 'red'
            })
          }
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          this.loader = true
          this.formData.status = this.status
          const loadingState = { loading: false, listReload: false }
          this.formData.active_role_id = this.$store.state.Auth.activeRoleId
          if (this.formData.id) {
            result = await RestApi.putData(teaGardenServiceBaseUrl, `${returnThreeUpdateApi}/${this.formData.id}`, this.formData)
          } else {
            result = await RestApi.postData(teaGardenServiceBaseUrl, returnThreeStoreApi, this.formData)
          }
          this.loader = false
          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          if (result.success) {
            this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            this.$router.push('return-three')
            } else {
            this.$refs.form.setErrors(result.errors)
          }
        }
      },
      async getFormData (id) {
        this.loader = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, returnThreeShowApi + '/' + id)
        if (result.success) {
          this.formData = Object.assign({}, this.formData, result.data)
          const typeName = this.formData.return_type === 1 ? 'garden' : 'factory'
          this.getGfAutoId(this.formData.garden_fact_id, typeName)
          this.loader = false
        } else {
          this.loader = false
        }
      },
      back () {
        this.$router.go(-1)
      },
      async getPreYearData () {
        const params = {
          year: this.formData.year,
          return_type: this.formData.return_type,
          garden_fact_id: this.formData.garden_fact_id
        }
        this.loader = true
          const result = await RestApi.getData(teaGardenServiceBaseUrl, preYearData, params)
          if (result.success) {
              if (result.data) {
                this.formData.details = result.data.details
              } else {
                this.formData.details = []
              }
              this.loader = false
          } else {
            this.formData.details = []
            this.loader = false
          }
      }
    }
  }
  </script>
